<template>
  <div :style="{backgroundColor: $route.query.backgroundColor ||'#eee'}" class="container prow-40">
    <h1 class="title pt-20 pb-10">《幼享乐隐私政策》</h1>
    <div class="f-fs-fe f-col date">
      <span>生效发布日期：2021年08月16日 </span>
      <span>更新日期：2021年08月16日 </span>
    </div>
    <div class="ti-1 pre-txt mt-10">
      幼享乐是广东柏斯融媒体科技有限公司(以下简称柏斯融媒体科技)开发的软件，为使用幼享乐（以下简称“本软件”）及服务，你应当阅读并遵守《隐私政策》（以下简称“本协议”）。
      在使用幼享乐的产品和服务前，请您务必仔细阅读并透彻理解本《隐私政策》（以下简称为“本政策”）。一旦您选择使用或继续使用，即表示您认可并接受本政策现有内容及其可能随时更新的内容，同意我们按照本政策收集、使用、披露、储存和分享，或以其他方式运用您的相关信息。
    </div>
    <div class="txt">
      <h2 class="ul-title pt-20 pb-10">一、关于信息收集</h2>
      <p class="ti-1">
        幼享乐会通过如下方式收集与您有关的信息。如果您不提供相关信息，无法注册成为我们的用户或无法享受我们的某些服务，或者无法达到相关服务拟达到的效果。
      </p>
      <h3 class="li-title">1、您提供的信息</h3>
      为了顺利的为您提供相关服务，幼享乐会收集您在幼享乐输入的或者以其他方式提供给我们的所有信息，包括：
      <div>
        1）您在注册账户时提供的姓名、电话号码、性别、微信名称、微信号等；
        <br />
        2）您在预订门票、保险、活动报名时提供的预定人和联系人的姓名、性别、证件号码、电子邮箱等信息；
        如您为其他人预订商品或服务，您需要按照提示提供该实际人的信息，并且您需要确保您已经取得该人的同意，并确保其已知晓并接受本隐私政策。
        <br />
        3）您通过幼享乐的服务向第三方提供的共享信息，以及您使用我们的服务时所储存的信息。
      </div>
      <h2 class="ul-title pt-20 pb-10">2、我们获取的您的信息</h2>
      <div class="mrow-10">
        <div class="ti-1">
          1）授权登录：我们可能经您同意后向第三方共享您的账户信息（头像、昵称及其他页面提示的信息），使您可以便捷地实现第三方账户的注册或登录。此外，我们可能会根据您的授权从第三方处获取您的第三方账户信息，并与您的幼享乐账户进行绑定，使您可通过第三方账户直接登录、使用我们的产品及/或服务。我们需要您的授权获取地理位置权限，用于轨迹记录服务。我们将在您授权同意的范围内使用您的相关信息。
        </div>
        <div class="ti-1">
          2）您使用服务时幼享乐收集如下信息：日志信息，指您使用我们的服务时，系统会通过cookies或其他方式自动采集的技术信息，包括但不限于：IP地址信息、硬件设备或软件信息、SDK或API版本、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、iOS供应商标识符（IDFV)、iOS广告标识符（IDFA)、安卓广告主标识符、网卡（MAC)地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、移动网络/国家代码、时区和网络状态（WiFi等)、硬盘、CPU和电池使用情况、位置信息（指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息）等。
        </div>
        <div class="ti-1">
          3）我们的产品集成高德地图定位
          SDK为了向您提供【基于位置的相关】功能/服务，我们的产品集成了【高德地图定位】SDK。在向您提供【定位】功能/服务时，【高德地图SDK】需要收集、使用您的【设备信息、位置信息、WLAN及其他传感器信息】，具体请您仔细阅读《高德地图开放平台隐私权政策》【请开发者附访问链接https://lbs.amap.com/home/privacy】}进行了解。
        </div>
        <div class="ti-1">
          4）我们的产品集成高德地图定位 SDK
          如果您使用定位、附近、照片墙、等功能，则需要注册/登录（登录即代表您注册成为幼享乐的用户）幼享乐，我们将根据您注册/登录的方式，收集以下个人信息：

          <div class="ml-10">
            <div class="ti-1">
              1.手机号码，是您选择使用手机号码注册/登录时，主动向我们提供的个人信息。同时，我们将通过发送短信验证码的方式来验证您的账户是否有效。您还可以通过手机号码找回、修改账号密码。您还可以自主选择是否在您的“更多资料”中提供您的婚姻状况和所在城市、生日、身高、收货地址，我们可以据此更好地为您提供记录、分析服务。
            </div>
            <div class="ti-1">
              2.第三方应用账号（微信）和昵称、头像、性别、所在国家、省市信息（请以授权登录界面显示的信息为准），是您选择使用第三方账号注册/登录，并授权幼享乐读取您在该第三方账号注册的上述信息。
            </div>
            <div class="ti-1">
              3.我们还会收集您的国际移动用户识别码IMSI、Android
              ID用于判断您上次登录幼享乐的方式，目的是方便您使用一键登录功能。
              如果您不提供这些信息，则您无法注册/登录我们产品，且无法使用需注册/登录的功能，但不会影响您正常使用无需注册/登录的功能。
            </div>
          </div>
        </div>

        <div class="ti-1">
          5）相机权限
          您在发布图片、视频、照片墙时，我们需申请调用您的相机权限、相册权限、闪光灯权限以及麦克风权限，如果您拒绝调用，将无法成功保存、发布视频，但不影响其它无需该权限的功能。
        </div>
        <div class="ti-1">
          6）我们的产品集成微信SDK 功能：第三方登录、分享
          收集个人信息类型或权限：日志信息、设备信息（设备型号、操作系统版本、唯一设备标识符IMEI、电池、信号强度等信息）、软件信息（软件版本号、浏览器类型）、IP地址、位置信息（可能基于iOS系统还会通过IP地址、GPS、WLAN（如WIFI）或基站。等途径获取地理位置信息）。
          微信SDK隐私政策链接：https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy
        </div>
        <div class="ti-1">
          7）我们的产品集成支付宝SDK 功能：电商支付
          获取个人信息类型或权限：设备型号、IP地址、设备软件版本信息、设备识别码、唯一设备标识符IMEI、位置信息、网络使用习惯以及其他与服务相关的日志信息。
          支付宝SDK隐私政策链接：https://render.alipay.com/p/c/k2h4n8ug
        </div>
        <div class="ti-1">
          8）我们的产品集成OPP0 PUSH SDK（基于Android系统） 功能：推送服务
          获取个人信息类型或权限：允许访问网络、允许挂载或反挂载外部文件系统、允许程序写入外部存储、允许访问电话状态、层叠窗口权限。
          OPP0 PUSH SDK隐私政策链接：暂无
        </div>
        <div class="ti-1">
          9）我们的产品集成VIVO PUSH SDK（基于Android系统） 功能：推送服务
          获取个人信息类型或权限：允许访问网络 VIVO PUSH SDK隐私政策链接：暂无
        </div>
        <div class="ti-1">
          10) 我们的产品J PUSH SDK 功能：推送服务
          获取个人信息类型或权限：允许访问网络、获取网络状态、获取当前接入的WIFI接入状态及WLAN热点接入信息、允许挂载或反挂载外部文件系统、允许写入和读取外部存储、开启电话状态访问权限和层叠窗口权限、Android
          Q后台定位权限、允许应用程序接收点亮屏幕或解锁广播、允许访问振动设备、允许改变WIFI连接状态、允许应用程序访问额外的位置提供命令、允许应用程序获取当前或最近运行的应用程序、通过WIFI或移动基站获取用户所在经纬度信息、获取华为角标（如有）、通过GPS芯片接收卫星的精准定位信息。
          J PUSH SDK隐私政策链接：https://www.jiguang.cn/license/privacy
        </div>
        <div class="ti-1">
          11）我们的产品集成HUAWEI PUSH SDK（基于Android系统） 功能：推送服务
          获取个人信息类型或权限：允许程序写入外部存储、允许访问网络、获取网络状态、获取当前接入的WIFI状态及WLAN热点信息、允许访问电话状态。
          HUAWEI PUSH SDK隐私政策链接：暂无
        </div>
        <div class="ti-1">
          12）我们的产品集成MI PUSH SDK（基于Android系统） 功能：推送服务
          获取个人信息类型或权限：允许程序写入外部存储、允许访问网络、获取网络状态、获取当前接入的WIFI接入状态及WLAN热点接入信息、允许访问电话状态、允许程序获取当前或最近运行的应用程序。
          MI PUSH
          SDK隐私政策链接：https://dev.mi.com/console/doc/detail?pId=1822
        </div>
        <div class="ti-1">
          13）我们的产品集成微信支付SDK 功能：微信支付
          获取个人信息类型或权限：微信账号、设备信息（包括设备型号、操作系统版本、唯一设备识别码IMEI、MAC地址）、IP地址、与该服务相关的日志信息、地理位置相关信息（拒绝开启GPS定位时则通过IP地址、GPS、WLAN(如Wifi)或基站途径获取地理位置信息）、应用程序版本号、浏览器类型。
          微信支付SDK 隐私政策链接：暂无
        </div>
        <div class="ti-1" style="display: none;">
          14）为了能够向您提供个性化的信息搜索及交易服务，我们会根据您的浏览及搜索记录、设备信息、位置信息、订单信息等，提取您的浏览、搜索偏好、行为习惯、位置信息等特征。
        </div>
      </div>
      <h2 class="ul-title pt-20 pb-10">3、其他合法途径收集您的信息</h2>
      <p class="ti-1">
        我们还会从其他合法来源收到关于您的信息并且将其加入我们的信息库，例如其他方使用我们的服务时所提供有关您的共享信息等等。
      </p>
      <h2 class="ul-title pt-20 pb-10">4、关于账号注销/禁用说明</h2>
      <p class="ti-1">
        您可以在我们的产品中直接申请注销账户。您可以通过移动端APP访问，我的-系统设置-注销服务-完成账号注销申请；您还可以通过问题反馈来完成账户注销申请。待审核通过后会自动注销账户，我们将停止为您提供产品与/或服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息。
      </p>

      <h2 class="ul-title pt-20 pb-10">二、关于信息使用</h2>
      <div class="ti-1">
        幼享乐利用收集的信息来提供、维护、保护和改进服务，同时开发新的服务为用户创造更好的体验，并提高我们的总体服务品质。
        您理解并同意，幼享乐可在如下情形使用您的信息：
        <div class="ti-1">
          1）为确保我们向您提供的产品和服务的安全性，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途时；
        </div>
        <div class="ti-1">
          2）向您提供服务特别是定制服务时，以及帮助我们设计改善我们现有服务时；
        </div>
        <div class="ti-1">
          3）向您提供相关的广告、评估我们服务中的广告和其他促销及推广活动的效果，并加以改善时；
        </div>
        <div class="ti-1">
          4）幼享乐会对产品使用情况进行统计，并会与公众分享这些统计信息，以展示我们服务的整体使用趋势时；
        </div>
        <div class="ti-1">5）我们需要使用您的信息来处理和解决法律纠纷时；</div>
        <div class="ti-1">
          6）您授权并同意接受我们向您的电子邮件、手机、通信地址等发送商业信息，包括不限于最新的产品信息、促销信息等；
        </div>
        <div class="ti-1">
          7）我们在必要时，例如因系统维护而暂停部分服务时，向您发出与服务有关的公告。您理解并认可您无法取消这些公告，因为这些公告与服务有关、性质不属于商业推广。
        </div>
      </div>

      <h2 class="ul-title pt-20 pb-10">三、关于信息披露</h2>
      <div class="ti-1">
        除非事先获得您的授权或本《隐私政策》约定外，幼享乐不会将这些信息对外公开或向幼享乐之外的第三方提供。
        您理解并同意，幼享乐可在如下情形依法披露您的个人信息：
        <div>
          <div class="ti-1">1）事先获得用户的明确授权；</div>
          <div class="ti-1">
            2）您使用了必须披露个人信息才能正常使用的幼享乐或其合作商提供的产品或服务，例如必须实名购买保险时提供的身份信息；
          </div>
          <div class="ti-1">
            3）根据法律、法规要求或政府主管部门的强制性要求，或者为了维护公共利益；
          </div>
          <div class="ti-1">
            4）为维护幼享乐的合法权益，或幼享乐服务的正常运营，例如查找、预防、处理欺诈或安全方面的问题；
          </div>
          <div class="ti-1">
            5）我们会向第三方（包括但不限于关联公司、合作伙伴等）共享您的订单信息、账户信息、设备信息以及位置信息等，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息；我们不会向任何机构披露任何用以识别用户的个人身份的资料，但从用户的用户名或其它可披露资料分析得出的资料不受此限。
          </div>
          <div class="ti-1">
            6）其他符合法律，以及符合幼享乐《服务协议》或其他相关协议、规定、指引的情形。
          </div>
          <div class="ti-1">
            7）幼享乐也会使用您的信息，通过各种方式向您发送营销信息，提供或推广我们或合作方提供的产品和服务。
          </div>
        </div>
      </div>

      <h2 class="ul-title pt-20 pb-10">四、关于敏感信息</h2>
      <div class="ti-1">
        某些个人信息因其特殊性会被认为是敏感信息，例如您的种族、宗教、个人健康和医疗信息等。相比其他个人信息，敏感个人信息受到更加严格的保护。
        请注意，您在使用我们的服务时所提供、上传或发布的内容和信息，例如有关您在雪圈中发布的照片等信息，可能会泄露您的敏感个人信息。您需要谨慎地考虑，是否在使用我们的服务时披露相关敏感个人信息。
        <span class="fw-b">您同意按本《隐私政策》来处理您的敏感个人信息。</span>
      </div>
      <h2 class="ul-title pt-20 pb-10">五、关于信息存储</h2>
      <div class="ti-1">
        所收集的用户信息和资料将保存在幼享乐及其关联公司的服务器上，或幼享乐委托机构的服务器上。
      </div>
      <h2 class="ul-title pt-20 pb-10">六、关于外部链接</h2>
      <div class="ti-1">
        幼享乐的产品或服务中含有到其他网站的链接。幼享乐对那些网站的隐私保护措施不负任何责任。我们在任何需要的时候增加商业伙伴或合作品牌的网页链接。
      </div>
      <h2 class="ul-title pt-20 pb-10">七、公开发布信息</h2>
      <div class="ti-1">
        您发布的内容或评论将被显示在公共区域，对任何用户来说是公开的。请注意所有在这些场所公开的信息会被公众阅读，请您在决定公布您的个人信息前仔细考虑。
      </div>
      <h2 class="ul-title pt-20 pb-10">八、信息安全</h2>
      <div class="ti-1">
        幼享乐尽力保护您的信息安全，以防信息的丢失、不当使用、未经授权的披露等，我们将为此采取合理的预防措施，使用符合行业标准的加密技术来保护您在使用本服务中涉及的数据。但是，您理解并确认，任何人无法保证互联网数据100%的安全，如果出现数据安全，将会由您个人承担。
        如果幼享乐得知有安全系统漏洞，我们在必要时通过您预留的联系方式与你联系或在我们的网络系统上发布通知，并提供可能的保护措施。
      </div>
      <h2 class="ul-title pt-20 pb-10">九、未成年人信息保护</h2>
      <div class="ti-1">
        幼享乐非常重视对未成年人信息的保护。若您是18周岁以下的未成年人，在使用幼享乐的产品和/或服务前，应事先取得您的家长或法定监护人的同意，并请要求您的父母或监护人阅读本《隐私政策》。
      </div>
      <h2 class="ul-title pt-20 pb-10">十、关于变更</h2>
      <div class="ti-1">
        幼享乐将根据法律、法规或政策，或幼享乐产品和服务的变化以及技术的更新，或其他幼享乐认为合理的原因，对本《隐私政策》进修改变更。变更以网站/App公告、或用户通知等合适的形式告知用户，或在网页中显著的位置予以发布。若您不接受变更后条款的，应立即停止使用，若您在本《隐私政策》变更后继续使用的，视为接受修订后的所有条款。
      </div>
      <h2 class="ul-title pt-20 pb-10">十一、适用范围</h2>
      <div class="ti-1">
        除另有明确约定外，我们所有的服务均适用本《隐私政策》。针对某些特定服务的特定隐私政策，将更具体地说明我们在该等服务中如何使用您的信息。该特定服务的隐私政策构成本《隐私政策》的一部分。
      </div>
      <div class="mrow-10 ti-1">
        如果您对本协议或本服务有意见或建议，可与柏斯融媒体科技联系，我们会给予您必要的帮助。
        本协议的最终解释权归广东柏斯融媒体科技有限公司所有。
        如有疑问请联系 youxiangle202163@163.com
      </div>
    </div>

    <div class="ti-1 pre-txt mt-10">&nbsp;</div>
    <div class="ti-1 pre-txt mt-10">
      <a href="/yxl-web/app/launch-app?backgroundColor=white">返回</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
};
</script>

<style lang="scss" scoped>
.title,
.txt {
  font-size: 0.28rem;
  color: #0a1738;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;

  .ul-title {
    font-size: 0.28rem;
    font-weight: bold;
  }
  .li-title {
    font-size: 0.26rem;
  }
}
.title {
  text-align: center;
  font-weight: bold;
}
.date {
  font-size: 0.2rem;
}
.ti-1 {
  text-indent: 1em;
}
.pre-txt {
  font-size: 0.24rem;
}
.fw-b {
  font-weight: bold;
}
</style>
